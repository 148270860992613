<template>
   <div class="cont">
      <!-- 面包屑 -->
      <el-row class="crumbs-box">
         <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>AIOT</el-breadcrumb-item>
            <el-breadcrumb-item>家居设备</el-breadcrumb-item>
            <el-breadcrumb-item>场景配置</el-breadcrumb-item>
         </el-breadcrumb>
      </el-row>
      <el-row class="content-box">
         <!-- 表头 -->
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label v-text="$t('msg.member_ID_a')">会员号</label>
                  <el-select class="width-220" @focus="getMemberList" v-model="userId" :placeholder="$t('msg.select')" >
                     <el-option v-for="item in members" :key="item.value" :label="item.label" :value="item.value"></el-option>
                  </el-select>
               </el-row>
               <el-row class="search-item">
                  <label>场景模式：</label>
                  <el-input class="width-220"  v-model="sceneName" :placeholder="$t('msg.scene_model')" clearable></el-input>
               </el-row>
               <el-row class="search-item">
                  <label v-text="$t('msg.status_a')">状态：</label>
                  <el-button-group class="width-220">
                     <el-button :class="state === 'VALID' ? 'bg-gradient': ''"  @click="state = 'VALID'"><span v-text="$t('msg.valid')">有效</span></el-button>
                     <el-button :class="state === 'INVALID' ? 'bg-gradient': ''"  @click="state = 'INVALID'"><span v-text="$t('msg.invalid')">无效</span></el-button>
                  </el-button-group>
               </el-row>
               <el-button class="bg-gradient" type="primary" @click="handleQuery(true)"><span v-text="$t('msg.search')">搜索</span></el-button>
               <el-button  type="primary" plain @click="handleQuery(false)"><span v-text="$t('msg.reset')">重置</span></el-button>
            </el-row>
         </el-row>
         <!-- 表格 -->
         <div class="table-box">
            <div class="table-head-btn">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button class="bg-gradient" type="primary" @click="handleAddEdit('add')"><span v-text="$t('msg.add_scene')">新建场景</span></el-button>
               </el-row>
            </div>
            <el-table
                  v-loading="loading"
                  :data="tableData"
                  highlight-current-row
                  @selection-change="handleSelectionChange"
                  style="width: 100%"
                  :stripe="true"
                  border fit>
               <el-table-column type="selection" width="60"></el-table-column>
               <el-table-column :label="$t('msg.number')" width="70">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column label="场景图片" min-width="70">
                  <template>
                     <div style="text-align: center;">
                        <img src="@/assets/图像 1.png" alt="" style="width: 35px;height: 35px;" >
                     </div>
                  </template>
               </el-table-column>
               <el-table-column prop="sceneName" :label="$t('msg.scene_model')" min-width="110"></el-table-column>
               <el-table-column prop="userName" label="会员号" min-width="150"></el-table-column>
               <el-table-column :label="$t('msg.unlock_status')" min-width="130">
                  <template slot-scope="scope">
                     <span v-if="scope.row.locked === 'LOCKED'" style="color: #67c23a;" v-text="$t('msg.are_unlocked')">已锁定</span>
                     <span v-else style="color: #F56C6C;" v-text="$t('msg.not_unlock')">未锁定</span>
                     <el-switch  style="margin: 0px 5px;" v-model="scope.row.locked"
                                 active-color="#67c23a"
                                 inactive-color="#dcdfe6"
                                 active-value="LOCKED"
                                 inactive-value="UNLOCK"
                                 @change="changeLocking(scope.row.id, scope.row.locked)">
                     </el-switch>
                  </template>
               </el-table-column>
               <el-table-column :label="$t('msg.sync_status')" :min-width="syncWidth">
                  <template slot-scope="scope">
                     {{ scope.row.syncGatewayState | filterSyncGatewayState(that) }}
                  </template>
               </el-table-column>
               <el-table-column :label="$t('msg.status')" min-width="110">
                  <template slot-scope="scope">
                     <span class="box-span mini bg-green" v-if="scope.row.state === 'VALID'"></span>
                     <span class="box-span mini bg-gray" v-else></span>
                  </template>
               </el-table-column>
               <el-table-column prop="createTime" :label="$t('msg.create_time')" min-width="150" sortable></el-table-column>
               <el-table-column :label="$t('msg.operating')" min-width="220" fixed="right">
                  <template slot-scope="scope">
                     <el-button  type="text" icon="el-icon-warning-outline" @click="handleDetail(scope.row)" >
                        <el-link type="primary" v-text="$t('msg.detail')">详情</el-link>
                     </el-button>
                     <el-button type="text" icon="el-icon-edit" @click="handleAddEdit('edit', scope.row)" >
                        <el-link type="primary" v-text="$t('msg.edit')">编辑</el-link>
                     </el-button>
                     <el-dropdown class="m-left-10">
                        <el-button type="text" >
                           <el-link type="primary" v-text="$t('msg.more')">更多</el-link><i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                           <el-dropdown-item>
                              <el-button v-if="scope.row.state === 'VALID'" type="text" icon="el-icon-error" @click="changeEnable(scope.row.id, 'INVALID')" >
                                 <el-link type="primary" v-text="$t('msg.disable')">禁用</el-link>
                              </el-button>
                              <el-button v-else type="text" icon="el-icon-success" @click="changeEnable(scope.row.id, 'VALID')" >
                                 <el-link type="primary" v-text="$t('msg.enable')">启用</el-link>
                              </el-button>
                           </el-dropdown-item>
                           <el-dropdown-item>
                              <el-button type="text" style="color: #F56C6C;" icon="el-icon-delete" @click="handleDel(scope.row.id)" >
                                 <el-link type="danger" v-text="$t('msg.remove')">删除</el-link>
                              </el-button>
                           </el-dropdown-item>
                        </el-dropdown-menu>
                     </el-dropdown>
                  </template>
               </el-table-column>
            </el-table>
         </div>
         <!-- 分页 -->
         <el-row class="pag_bottom">
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="currChange"/>
            <div class="pag_button" style="margin-top: 20px">
               <el-button   type="danger" class="m-bottom-5" @click="batchDelete()"><span v-text="$t('msg.all_delete')">批量删除</span></el-button>
               <el-button   type="info" class="m-bottom-5" @click="batchLocking('LOCKED')"><span v-text="$t('msg.all_unlocking')">批量解锁</span></el-button>
               <el-button   type="success" class="m-bottom-5" @click="batchLocking('UNLOCK')"><span v-text="$t('msg.all_locking')">批量锁定</span></el-button>
            </div>
         </el-row>
      </el-row>
   </div>
</template>
<script>
   import { mapState } from 'vuex'
   import { urlObj } from '@/api/interface'
   import {getDict} from '@/common/js/common'
   export default {
      name: 'homeScenesConfig',
      data () {
         return {
            that: this,
            tableData: [],         // 表格数据
            members: [],           // 会员列表
            sceneIds: [],          // 批量场景IDs
            loading: true,         // 加载效果
            total: 0,              // 数据总数
            syncWidth: 0,          // 同步栏宽度
            /* 查询列表字段 */
            page: '1',             // 当前页
            limit: 0,              // 每页数
            userId: '',            // 会员ID
            state: '',             // 场景状态
            sceneName: '',         // 场景模式
            /* 提示文本 */
            confirm: '确定',
            cancel: '取消',
            prompt: '提示！',
            confirm_lock: '确定锁定？',
            confirm_unlock: '确定解锁？',
            confirm_remove_scene: '若删除该场景，该房间的场景将会失效，确定删除？',
            confirm_remove_all: '批量删除场景，只会删除未锁定的场景，确认删除？',
            remove_success: '移除成功！',
            state_change_succss: '状态更改成功！',
            select_remove_scene: '请选择要删除的场景！',
            select_lock_scene: '请选择要锁定的场景！',
            select_unlock_scene: '请选择要解锁的场景！',
         }
      },
      computed: {
         ...mapState(['dictData'])
      },
      beforeRouteEnter(to, from, next){
         if (from.name === 'homeScenesEdit'){
            next(vm => {
               vm.getSceneList()
            })
            return
         }
         next()
      },
      beforeRouteLeave(to, from, next){
         if (to.name === 'homeScenesDetail' || to.name === 'homeScenesEdit'){
            this.$store.dispatch('setCachePage', from.name)
            next()
            return
         }
         this.$store.dispatch('setCachePage', 'fakePage')
         next()
      },
      mounted(){
         this.limit = sessionStorage.getItem('pageSize') * 1
         getDict(['scene-sync-gateway'])
         this.getSceneList()
      },
      methods: {
         // 获取场景列表
         getSceneList(){
            const url = urlObj.getHomeScenePage + `?limit=${this.limit}&page=${this.page}`
            const param = {
               userId: this.userId,
               state: this.state,
               sceneName: this.sceneName
            }
            this.$axios.post(url, param, 'json').then(async res => {
               if (res.success) {
                  this.total = res.total
                  this.tableData = res.records
                  this.loading = false
               }
            })
         },
         // 获取会员列表
         getMemberList(){
            if (this.members.length > 0) return
            const url = urlObj.members
            this.$axios.post(url, {}, 'json').then(res => {
               if (res.success) {
                  let members = res.records
                  this.members = members.map(item => {
                     return { value: item.id, label: item.userName }
                  })
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 搜索场景
         handleQuery(bool){
            if (bool) return this.getSceneList()
            this.userId = ''
            this.state = ''
            this.sceneName = ''
         },
         // 改变每页数
         pageChange(num){
            this.limit = num
            this.getSceneList()
         },
         // 改变当前页
         currChange(num){
            this.page = num
            this.getSceneList()
         },
         // 新增/编辑场景
         handleAddEdit(act, row){
            let id, userName
            if(act === 'edit') id = row.id, userName = row.userName
            this.$router.push({path: '/home_scenes_edit', query: {act, id, userName}})
         },
         // 场景详情
         handleDetail(row){
            this.$router.push({path: '/home_scenes_detail', query: {id: row.id, userName: row.userName}})
         },
         // 删除场景
         handleDel(sceneId){
            this.$confirm(this.confirm_remove_scene, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               const url = urlObj.delHomeScene
               const param = {
                  sceneId,
               }
               this.$axios.get(url, param).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getSceneList()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            })
         },
         // 锁定/解锁场景
         changeLocking(sceneId, locked){
            const url = urlObj.switchHomeLocked
            const param = {
               sceneId,
               locked
            }
            this.$axios.get(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.state_change_succss,
                     type: 'success'
                  })
                  this.getSceneList()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 启用/禁用场景
         changeEnable(sceneId, state){
            const url = urlObj.homeSceneState
            const param = {
               sceneId,
               state
            }
            this.$axios.get(url, param).then(res => {
               if (res.success) {
                  this.$message({
                     showClose: true,
                     message: this.state_change_succss,
                     type: 'success'
                  })
                  this.getSceneList()
               }
            }).catch(error => {
               this.$message.error(error)
            })
         },
         // 批量选中场景
         handleSelectionChange(arr){
            this.sceneIds = arr.map(item => {
               return item.id
            })
         },
         // 封装批量操作的提示语
         batchPrompt(prompt){
            if (this.sceneIds.length <= 0){
               this.$alert(prompt, '', {
                  confirmButtonText: this.confirm,
                  type: 'error'
               })
               return true
            }
            return false
         },
         // 批量删除
         batchDelete(){
            if (this.batchPrompt(this.select_remove_scene)) return
            this.$confirm(this.confirm_remove_all, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               let url = urlObj.deleteBatchScene
               let param = {
                  sceneIds: this.sceneIds.join()
               }
               this.$axios.get(url, param).then((res) => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.remove_success,
                        type: 'success'
                     })
                     this.getSceneList()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            }).catch(cancel => {
               console.log(cancel)
            })
         },
         // 批量锁定/解锁
         batchLocking(type){
            let prompt = type === 'LOCKED' ? this.select_lock_scene : this.select_unlock_scene
            if (this.batchPrompt(prompt)) return
            this.$confirm(type === 'LOCKED' ? this.confirm_lock : this.confirm_unlock, this.prompt, {
               confirmButtonText: this.confirm,
               cancelButtonText: this.cancel,
               type: 'error'
            }).then(() => {
               const url = urlObj.sceneBatchLock
               const param = {
                  sceneIds: this.sceneIds.join(),
                  locked: type
               }
               this.$axios.get(url, param).then(res => {
                  if (res.success) {
                     this.$message({
                        showClose: true,
                        message: this.state_change_succss,
                        type: 'success'
                     })
                     this.getSceneList()
                  }
               }).catch(error => {
                  this.$message.error(error)
               })
            }).catch(cancel => {
               // console.log(cancel)
            })
         },
      },
      filters: {
         filterSyncGatewayState(val, that) {
            if (val && that.dictData['scene-sync-gateway']) return that.dictData['scene-sync-gateway'][val] || val
         }
      }
   }
</script>
<style scoped lang="scss">
   .m-bottom-15{
      line-height: 17px;
   }
   .font-icon{
      font-size:30px;
      margin-right: 10px;
      color: #409EFF;
      cursor: pointer;
   }
   .cks{
      line-height:28px;
      margin-right: 10px!important;
   }
   .execute_top{
      position: relative;
      top: -21px;
      right: 0;
   }
   .p-title{
      color: #FFF;
      background:#409EFF;
      line-height: 28px;
      text-align: center;
   }
   .device-box{
      border:1px solid #dedede;
      box-sizing: border-box;
      display:inline-block;
      height:400px;
      width:45%;
      overflow:auto;
   }
   .add-btn-box{
      box-sizing: border-box;
      display:inline-block;
      height: 400px;
      width: 88px;
      text-align: center;
      overflow: hidden;
   }
   .el-col > .del-icon{
      color: #F56C6C;
      cursor: pointer;
   }
   .device-box .el-radio{
      margin-right: 10px!important;
   }
   .device-box .el-radio__label{
      padding-left: 5px!important;
   }
   .copy-tit{
      background: #409EFF;
      color: white;
      // background: rgba(222, 222, 222, 0.35);
      // color: #b5b3b3;
      text-align: center;
      line-height: 50px;
      position: relative;
      font-size: 20px;
   }
   .success-room-box, .failure-room-box{
      width: 48%;
      float: left;
   }
   .failure-room-box{
      float: right;
   }
   .examp .examp_box{
      box-sizing: border-box;
      height: auto;
      padding: 0px 15px;
   }
   .examp .examp_bottom{
      position: relative;
      border-bottom: 1px solid #dedede;
   }
   .label_icon i{
      position: absolute;
   }
   .examp_edit{
      position: absolute;
      top: 0;
      right: 15px;
      color: white;
   }
   .examp-box{
      width: 46%;
   }
   .activeNames{
      height: 48px;
      line-height: 48px;
   }
   .newType_bottom{
      padding-left: 10px;
      border-bottom: 1px solid #dedede;
   }
   #mandatory, .label_icon{
      position: relative;
   }
   #mandatory .room-list{
      padding: 0;
   }
   #mandatory .mand_copy{
      border: 1px solid #dedede;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 40px;
      height: 40px;
      line-height: 40px;
      margin-left: -24px;
      margin-top: -20px;
      text-align: center;
      padding: 4px;
   }
   .room-list .examp_top{
      height: 50%;
   }
   .room-list{
      padding: 15px;
      border: 1px solid #dedede;
      box-sizing: border-box;
      display: inline-block;
      width: 100%;
      height: 300px;
      overflow: auto;
      .box-pad{
         color: #00BFBF;
         border: 1px solid rgba(0, 191, 191, 1);
         background: rgba(0, 191, 191, 0.2);
         padding: 2px;
      }
   }
   .room-list-box{
      max-height: 300px;
      overflow: auto;
   }
   .prompt_info{
      padding: 0 5px;
   }
   .room-list-info{
      display: flex;
      justify-content: flex-start;
      // line-height: 17px;
   }
   .over_info{
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
   }
   .copy_scene >>> .el-dialog{
      min-height: 450px;
   }
   .pag_bottom {
      position: relative;
   }
   .pag_button {
      position: absolute;
      left: 10px;
      top: -6px;
   }
   .type-box{
      border: 1px solid #dedede;
      margin-top: 10px;
      padding: 10px;
      max-height: 330px;
      /* padding-left: 20px; */
      overflow:auto;
   }
   .type-matching{
      border: 1px solid #dedede;
      margin-top: 10px;
      padding: 10px;
   }
   .chenckbox{
      border: 1px solid #dedede;
      min-height: 270px;
      overflow:auto;
      padding: 10px;
      box-sizing: border-box;
   }
   .lab-box div{
      display: inline-block;
      margin-bottom: 10px;
      padding-left: 10px;
   }
   .lab-boxs div{
      margin-right: 5px;
   }
   .examp_name{
      position: relative;
   }
   .examp_name span{
      position: absolute;
      top: 28px;
      left: 0;
      width: 200px;
      height: 48px;
      line-height: 48px;
      z-index: 9;
   }
   .edit_device .m-bottom-15{
      line-height: 30px;
   }
   .disc-padding{
      padding-right: 30px;
      display: inline-block;
      width: 40%;
   }
   .collapse_li{
      min-height: 200px;
      max-height: 520px;
      overflow: auto;
      position: relative;
   }
   .collapse_li_img{
      position: absolute;
      margin: auto;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      text-align: center;
   }
   .collapse_li_img img{
      margin-top: 6%;
      height: 50px;
      width: 50px;
   }
   .collapse_li span{
      font-size: 14px;
   }
   .dialog-footer .loading_button{
      position: absolute;
      left: 0;
      top: 0;
      border: 0;
      background: #fff;
      color: #409EFF;
      font-size: 32px;
   }
   .loading_button span{
      font-size: 16px;
      color: black;
      vertical-align: middle;
   }
   .floor-bottom{
      /deep/.el-tabs__header{
         margin-bottom: 0;
      }
   }
</style>
